import React from "react";
import { Modal, Button } from "react-bootstrap";

const NotifierModal = ({ description, handleClose }) => {
  return (
    <Modal show={description} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ошибка</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotifierModal;
