const HotroomSingleLine = () => {
  return (
    <svg
      width="201"
      height="28"
      viewBox="0 0 201 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 4.34486C0.5 3.16638 0.929523 2.15951 1.78909 1.32424C2.64865 0.489482 3.64364 0.0718457 4.77301 0.0718457C5.9763 0.0718457 6.98938 0.495684 7.81224 1.34284C8.63511 2.18949 9.04654 3.20256 9.04654 4.38156V9.72192H17.4086V4.38156C17.4086 3.20256 17.8324 2.18949 18.6796 1.34284C19.5267 0.495684 20.5398 0.0718457 21.7188 0.0718457C22.873 0.0718457 23.8674 0.489482 24.7027 1.32424C25.5375 2.15951 25.9551 3.16638 25.9551 4.34486V22.986C25.9551 24.165 25.5437 25.1719 24.7208 26.0071C23.8979 26.8419 22.8973 27.2595 21.7188 27.26C20.5398 27.2595 19.5329 26.8543 18.6976 26.0438C17.8629 25.2328 17.4453 24.213 17.4453 22.9844V17.0879H9.00933V22.9844C9.00933 24.213 8.59221 25.2204 7.75694 26.0071C7.3884 26.4 6.92838 26.707 6.37533 26.9277C5.82279 27.1489 5.28885 27.2595 4.77301 27.26C3.59454 27.2595 2.58766 26.8419 1.75239 26.0071C0.917635 25.1719 0.5 24.165 0.5 22.986V4.34486Z"
        fill="#F5D14F"
      />
      <path
        d="M31.2551 6.04125C32.5566 4.17481 34.2385 2.70119 36.3019 1.62041C38.3648 0.540134 40.5625 0 42.8957 0C45.2532 0 47.4514 0.52773 49.4895 1.58371C51.528 2.63968 53.2105 4.1257 54.5363 6.04125C56.182 8.37442 57.0049 10.904 57.0049 13.63C57.0049 16.356 56.182 18.8856 54.5363 21.2183C53.2348 23.1338 51.5585 24.6198 49.5081 25.6758C47.4576 26.7313 45.2532 27.2595 42.8957 27.26C40.5382 27.2595 38.34 26.7313 36.3019 25.6758C34.2634 24.6198 32.5809 23.1338 31.2551 21.2183C29.6094 18.8608 28.7865 16.3312 28.7865 13.63C28.7865 10.9283 29.6094 8.39872 31.2551 6.04125ZM48.495 12.4888C48.2495 10.9655 47.605 9.73742 46.5609 8.80395C45.5173 7.87047 44.2954 7.40373 42.8957 7.40373C41.2996 7.40373 39.9547 7.98677 38.862 9.15387C37.7693 10.3205 37.2225 11.8132 37.2225 13.631C37.2225 15.4489 37.7693 16.9411 38.862 18.1077C39.9547 19.2748 41.2872 19.8579 42.859 19.8579C43.227 19.8579 43.5956 19.8212 43.9641 19.7472C45.3147 19.4774 46.426 18.7838 47.2979 17.6658C48.1699 16.5483 48.6056 15.2153 48.6056 13.6677C48.6056 13.2992 48.5689 12.9064 48.495 12.4888Z"
        fill="#F5D14F"
      />
      <path
        d="M74.9461 0.368567C75.953 0.368567 76.8125 0.732965 77.5248 1.46279C78.237 2.19211 78.5932 3.06769 78.5932 4.08904C78.5932 5.10935 78.237 5.96013 77.5248 6.64137C76.8125 7.3221 75.953 7.66272 74.9461 7.66272H71.8521V23.0093C71.8521 24.1681 71.4469 25.1538 70.6364 25.9674C69.8259 26.7809 68.8309 27.1877 67.6525 27.1877C66.4735 27.1877 65.4728 26.7763 64.6499 25.9539C63.8276 25.1305 63.4161 24.1299 63.4161 22.9514V7.66272H60.5056C59.4745 7.66272 58.6149 7.30659 57.9269 6.59434C57.2395 5.88208 56.8958 5.01011 56.8958 3.97895C56.8958 2.94726 57.2519 2.0877 57.9641 1.40025C58.6764 0.71229 59.536 0.368567 60.5428 0.368567H74.9461Z"
        fill="#F5D14F"
      />
      <path
        d="M81.0856 4.83852C81.0856 3.43468 81.466 2.3389 82.2274 1.55067C82.9888 0.762433 84.0447 0.368572 85.3953 0.368572H93.4632C96.0171 0.368572 98.0986 0.939721 99.7071 2.0815C101.316 3.2238 102.365 4.838 102.857 6.92514C103.053 7.78471 103.152 8.57088 103.152 9.28313C103.152 10.4616 102.937 11.5424 102.507 12.5245C102.077 13.5065 101.457 14.3537 100.646 15.0659L100.499 15.2132L99.6518 15.8764L99.136 16.2816L102.267 20.4073C102.881 21.1935 103.188 22.0774 103.188 23.0594C103.188 24.4845 102.588 25.6262 101.389 26.4853C100.629 27.0254 99.8089 27.2957 98.9271 27.2957C97.4577 27.2957 96.1712 26.4977 95.0692 24.9011L89.5955 16.7975V23.0667C89.5949 24.2534 89.1882 25.2551 88.3746 26.0713C87.5605 26.8874 86.549 27.2957 85.3405 27.2957C84.1812 27.2957 83.1821 26.8766 82.3437 26.0397C81.5053 25.2024 81.0856 24.181 81.0856 22.9741V4.83852ZM94.6407 9.17045C94.6407 8.75282 94.5058 8.40909 94.2355 8.13929C93.9657 7.86948 93.6467 7.73457 93.2782 7.73457H89.5955V10.9004H93.1309C93.5976 10.9004 93.9781 10.7345 94.2727 10.4032C94.5673 10.0719 94.6898 9.66097 94.6407 9.17045Z"
        fill="white"
      />
      <path
        d="M108.693 6.04125C109.994 4.17481 111.676 2.70119 113.74 1.62041C115.803 0.540134 118 0 120.334 0C122.691 0 124.889 0.52773 126.927 1.58371C128.966 2.63968 130.648 4.1257 131.974 6.04125C133.62 8.37442 134.443 10.904 134.443 13.63C134.443 16.356 133.62 18.8856 131.974 21.2183C130.673 23.1338 128.996 24.6198 126.946 25.6758C124.895 26.7313 122.691 27.2595 120.334 27.26C117.976 27.2595 115.778 26.7313 113.74 25.6758C111.701 24.6198 110.019 23.1338 108.693 21.2183C107.047 18.8608 106.224 16.3312 106.224 13.63C106.224 10.9283 107.047 8.39872 108.693 6.04125ZM125.933 12.4888C125.687 10.9655 125.043 9.73742 123.999 8.80395C122.955 7.87047 121.733 7.40373 120.334 7.40373C118.737 7.40373 117.393 7.98677 116.3 9.15387C115.207 10.3205 114.66 11.8132 114.66 13.631C114.66 15.4489 115.207 16.9411 116.3 18.1077C117.393 19.2748 118.725 19.8579 120.297 19.8579C120.665 19.8579 121.033 19.8212 121.402 19.7472C122.753 19.4774 123.864 18.7838 124.736 17.6658C125.608 16.5483 126.043 15.2153 126.043 13.6677C126.043 13.2992 126.007 12.9064 125.933 12.4888Z"
        fill="white"
      />
      <path
        d="M139.416 6.04125C140.717 4.17481 142.399 2.70119 144.463 1.62041C146.526 0.540134 148.723 0 151.057 0C153.414 0 155.612 0.52773 157.65 1.58371C159.689 2.63968 161.371 4.1257 162.697 6.04125C164.343 8.37442 165.166 10.904 165.166 13.63C165.166 16.356 164.343 18.8856 162.697 21.2183C161.396 23.1338 159.719 24.6198 157.669 25.6758C155.619 26.7313 153.414 27.2595 151.057 27.26C148.699 27.2595 146.501 26.7313 144.463 25.6758C142.424 24.6198 140.742 23.1338 139.416 21.2183C137.77 18.8608 136.947 16.3312 136.947 13.63C136.947 10.9283 137.77 8.39872 139.416 6.04125ZM156.656 12.4888C156.41 10.9655 155.766 9.73742 154.722 8.80395C153.678 7.87047 152.456 7.40373 151.057 7.40373C149.46 7.40373 148.116 7.98677 147.023 9.15387C145.93 10.3205 145.383 11.8132 145.383 13.631C145.383 15.4489 145.93 16.9411 147.023 18.1077C148.116 19.2748 149.448 19.8579 151.02 19.8579C151.388 19.8579 151.756 19.8212 152.125 19.7472C153.476 19.4774 154.587 18.7838 155.459 17.6658C156.331 16.5483 156.767 15.2153 156.767 13.6677C156.767 13.2992 156.73 12.9064 156.656 12.4888Z"
        fill="white"
      />
      <path
        d="M170.145 4.71494C170.49 2.57818 171.607 1.15419 173.498 0.441932C174.284 0.147313 175.033 5.34145e-06 175.745 5.34145e-06C176.998 5.34145e-06 178.103 0.361817 179.06 1.08648C180.018 1.81114 180.681 2.787 181.049 4.0151L183.776 13.2615L186.502 4.0151C186.87 2.81181 187.546 1.84163 188.528 1.10509C189.51 0.368021 190.615 5.34145e-06 191.843 5.34145e-06C192.556 5.34145e-06 193.305 0.147313 194.09 0.441932C195.932 1.12938 197.05 2.5544 197.443 4.71546L200.427 22.2148C200.476 22.435 200.5 22.6624 200.5 22.8961C200.5 23.1286 200.5 23.3685 200.5 23.6145C200.402 24.6214 199.947 25.4685 199.137 26.1565C198.327 26.844 197.394 27.1877 196.338 27.1877C195.896 27.1877 195.429 27.1143 194.937 26.967C193.268 26.402 192.334 25.2721 192.138 23.5778L190.848 13.0051L187.46 24.0931C186.968 25.7389 186.195 26.7333 185.139 27.0771C184.598 27.2704 184.107 27.3681 183.665 27.3681C181.946 27.3681 180.767 26.2754 180.129 24.0911L176.703 13.003L175.45 23.5757C175.352 24.3371 175.051 25.0245 174.548 25.6386C174.044 26.2526 173.412 26.6946 172.651 26.9649C172.16 27.1122 171.681 27.1856 171.214 27.1856C170.158 27.1856 169.237 26.8543 168.451 26.1911C167.665 25.528 167.211 24.6684 167.088 23.6124C167.063 23.4894 167.051 23.3054 167.051 23.0599C167.051 22.9369 167.051 22.802 167.051 22.6547C167.1 22.4583 167.125 22.311 167.125 22.2127L170.145 4.71494Z"
        fill="white"
      />
    </svg>
  );
};

export default HotroomSingleLine;
