import { Col, Form } from "react-bootstrap";

const DashboardTextFilter = ({
  label,
  onInputChange,
  placeholder,
  value = "",
}) => {
  return (
    <Col md={4} className="mb-4">
      <Form.Group controlId="formPeriod">
        <Form.Label className="label-font">{label}</Form.Label>
        <Form.Control
          value={value}
          type="text"
          name="period"
          onChange={(e) => onInputChange(e.target.value)}
          placeholder={placeholder}
        />
      </Form.Group>
    </Col>
  );
};

export default DashboardTextFilter;
