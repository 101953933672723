import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  Form,
  Navbar,
  Button,
} from "react-bootstrap";
import { formatCurrency, formatToMoney } from "../../utils/formatters";
import { useGetRequest } from "../../hooks/useApi";
import { useNavigate, useParams } from "react-router-dom";
import "./style/ReservationDetails.css";

const RoomStaysRequestTable = ({ roomStays, className }) => {
  return (
    <Table className={`${className} dark-table`} bordered>
      <thead>
        <tr>
          <th>Условия размещения</th>
          <th>Условия отмены бронирования</th>
        </tr>
      </thead>
      <tbody>
        {roomStays &&
          roomStays.map((stay, index) => (
            <tr key={index}>
              <td>{stay.fullPlacementsName}</td>
              <td>{stay.cancellationText}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

const RoomStaysResponseTable = ({ roomStays, className }) => {
  return (
    <Table className={`${className} dark-table`} bordered>
      <thead>
        <tr>
          <th>Категория номера</th>
          <th>Тарифный план</th>
          <th>дата и время заезда</th>
          <th>дата и время выезда</th>
        </tr>
      </thead>
      <tbody>
        {roomStays &&
          roomStays.map((stay, index) => (
            <tr key={index}>
              <td>{stay.roomType.name}</td>
              <td>{stay.ratePlan.name}</td>
              <td>
                {new Date(stay.stayDates.arrivalDateTime).toLocaleString()}
              </td>
              <td>
                {new Date(stay.stayDates.departureDateTime).toLocaleString()}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

const ReservationField = ({ id, label, value, valueClass, ...props }) => {
  return (
    <Col {...props}>
      <Form.Group controlId={id}>
        <Form.Label className="label-font">{label}</Form.Label>
        <Form.Control
          className={valueClass}
          type="text"
          readOnly
          value={value || ""}
        />
      </Form.Group>
    </Col>
  );
};

const ReservationDetails = () => {
  const [reservationInfo, setReservationInfo] = useState([]);
  const [reservationOrder, setReservationOrder] = useState([]);
  const [reservationCustomer, setReservationCustomer] = useState([]);
  const [reservationPayments, setReservationPayments] = useState([]);
  const [roomStaysResponse, setRoomStaysResponse] = useState(null);
  const [roomStaysRequest, setRoomStaysRequest] = useState(null);

  const { id } = useParams();

  const getRequest = useGetRequest();

  const navigate = useNavigate();

  const reservationProcessing = (fetchedReservation) => {
    console.log(fetchedReservation);

    const orderCurrency = formatCurrency(fetchedReservation?.order_currency);

    const fetchedReservationOrder = [
      {
        id: "orderId",
        label: "Номер заказа",
        value: fetchedReservation?.order_id,
      },
      {
        id: "orderDate",
        label: "Дата заказа",
        value: fetchedReservation?.order_date,
      },
      {
        id: "orderSum",
        label: "Сумма заказа",
        value: `${formatToMoney(
          fetchedReservation?.order_sum
        )} ${orderCurrency}`,
      },
      {
        id: "orderStatus",
        label: "Статус",
        value: fetchedReservation?.order_status,
      },
      {
        id: "paymentSum",
        label: "Оплачено",
        value: `${formatToMoney(
          fetchedReservation?.payment_sum
        )} ${orderCurrency}`,
      },
      {
        id: "operationCode",
        label: "Операция",
        value: fetchedReservation?.operation_code,
      },

      {
        id: "price",
        label: "Стоимость номера",
        value: `${formatToMoney(fetchedReservation?.price)} ${orderCurrency}`,
      },
      {
        id: "refundStatus",
        label: "Статус возврата",
        value: fetchedReservation?.refund_status,
      },
      {
        id: "margin",
        label: "Маржа",
        value: `${formatToMoney(fetchedReservation?.margin)} ${orderCurrency}`,
      },
      {
        id: "refundDate",
        label: "Дата возврата",
        value: fetchedReservation?.refund_date,
      },
      {
        id: "refundSum",
        label: "Сумма возврата",
        value: `${formatToMoney(
          fetchedReservation?.refund_sum
        )} ${orderCurrency}`,
      },
    ];

    const fetchedReservationInfo = [
      {
        id: "cityName",
        label: "Город",
        value: fetchedReservation?.city_name,
      },
      {
        id: "hotelName",
        label: "Отель",
        value: fetchedReservation?.hotel_name,
      },
      {
        id: "bookingNo",
        label: "Номер бронирования",
        value: fetchedReservation?.booking_no,
      },
      {
        id: "bookingStatus",
        label: "Статус бронирования",
        value: fetchedReservation?.booking_status,
      },
    ];

    const fetchedReservationCustomer = [
      {
        id: "customerName",
        label: "ФИО",
        value: fetchedReservation?.customer_name,
      },
      {
        id: "customerPhone",
        label: "Телефон",
        value: fetchedReservation?.customer_phone,
      },
      {
        id: "customerEmail",
        label: "Email",
        value: fetchedReservation?.customer_email,
      },
    ];

    setReservationOrder(fetchedReservationOrder);
    setReservationInfo(fetchedReservationInfo);
    setReservationCustomer(fetchedReservationCustomer);
    setReservationPayments(fetchedReservation?.payments || []);

    const roomStaysProcessing = (reservation, key, callback) => {
      if (!reservation[key]) return;

      const parsedBooking = JSON.parse(reservation[key]);
      const roomStays = parsedBooking?.booking?.roomStays;
      roomStays && callback(roomStays);
    };

    roomStaysProcessing(
      fetchedReservation,
      "booking_response",
      setRoomStaysResponse
    );
    roomStaysProcessing(
      fetchedReservation,
      "booking_request",
      setRoomStaysRequest
    );
  };

  useEffect(() => {
    const fetchOrder = async () => {
      const response = await getRequest("reservation", {
        id,
      });
      if (response.success) {
        reservationProcessing(response.data);
      } else {
        console.log(response.errorCode);
      }
    };
    if (!id) return;

    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleBack = () => {
    navigate(-1); // Возвращает на предыдущую страницу
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className="mb-4">
        <Container fluid className="default-container">
          <Button variant="secondary" onClick={handleBack} className="me-2">
            Назад
          </Button>
          <Navbar.Brand>{`Информация о бронировании`}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Container>
      </Navbar>
      <Container className="default-container">
        <Row>
          {/* Блок ордер */}
          <Col md={4}>
            <Card className="mb-3">
              <Card.Header className="reservation-card-header">
                Заказ
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    {reservationOrder.map((order, index) => {
                      return (
                        <ReservationField
                          id={order.id}
                          key={order.id}
                          label={order.label}
                          value={order.value}
                          md={!!index ? 6 : 12}
                          className="mb-4"
                          valueClass={index && !(index % 2) && "money"}
                        />
                      );
                    })}
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md={8}>
            {/* Блок бронирование */}
            <Card className="mb-3">
              <Card.Header className="reservation-card-header">
                Бронирование
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    {reservationInfo.map((info) => {
                      return (
                        <ReservationField
                          id={info.id}
                          key={info.id}
                          label={info.label}
                          value={info.value}
                          md={6}
                          className="mb-4"
                        />
                      );
                    })}
                  </Row>
                  <Row className="mb-3 mt-2">
                    <Col>
                      <RoomStaysResponseTable roomStays={roomStaysResponse} />
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col>
                      <RoomStaysRequestTable roomStays={roomStaysRequest} />
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          {/* Блок покупатель */}
          <Col md={4}>
            <Card className="mb-3">
              <Card.Header className="reservation-card-header">
                Покупатель
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    {reservationCustomer.map((customer, index) => {
                      return (
                        <ReservationField
                          id={customer.id}
                          key={customer.id}
                          label={customer.label}
                          value={customer.value}
                          md={!!index ? 6 : 12}
                          className="mb-4"
                        />
                      );
                    })}
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            {/* Блок платежи */}
            <Card className="mb-3">
              <Card.Header className="reservation-card-header">
                Платежи
              </Card.Header>
              <Card.Body>
                <Table bordered className="dark-table">
                  <thead>
                    <tr>
                      <th>Дата платежа</th>
                      <th>Операция</th>
                      <th>Статус</th>
                      <th>Провайдер</th>
                      <th>Сумма</th>
                      <th>First 6</th>
                      <th>Last 4</th>
                      <th>Сторона отказа</th>
                      <th>Причина отказа</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reservationPayments.map((payment) => (
                      <tr key={payment.id}>
                        <td>{payment?.payment_created_at}</td>
                        <td>{payment?.operation_code}</td>
                        <td>{payment?.status_code}</td>
                        <td>{payment?.provider_code}</td>
                        <td className="money">{`${formatToMoney(
                          payment?.payment_amount
                        )} ${formatCurrency(payment.payment_currency)}`}</td>
                        <td>{payment?.first6}</td>
                        <td>{payment?.last4}</td>
                        <td>{payment?.payment_cancel_party}</td>
                        <td>{payment?.payment_cancel_reason}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReservationDetails;
