const defaultCurrencies = {
  RUB: "₽",
  CNY: "¥",
  USD: "$",
  EUR: "€",
  GBP: "£",
};

export const formatCurrency = (currency, currencies = defaultCurrencies) => {
  return currencies[currency] || "";
};

export const formatToMoney = (value, locale = "ru-RU") => {
  if (isNaN(value)) return "";
  return Number(value).toLocaleString(locale);
};
