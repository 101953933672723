import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Loading = () => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={{ minHeight: "100vh" }}
  >
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

const PrivateRoute = ({ children, redirectTo = "/login" }) => {
  const { authToken, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <Loading />;
  }

  if (!authToken)
    return <Navigate to={redirectTo} state={{ from: location }} />;

  return children;
};

export default PrivateRoute;
