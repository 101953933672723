import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [username, setUsername] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get("auth_token");
    const username = Cookies.get("username");
    if (token) {
      setAuthToken(token);
    }
    if (username) {
      setUsername(username);
    }

    setLoading(false);
  }, []);

  const authLogin = (token, username) => {
    setAuthToken(token);
    setUsername(username);
    Cookies.set("auth_token", token, { sameSite: "strict" });
    Cookies.set("username", username, { sameSite: "strict" });
  };

  const authLogout = () => {
    setAuthToken(null);
    setUsername(null);
    Cookies.remove("auth_token", { sameSite: "strict" });
    Cookies.remove("username", { sameSite: "strict" });
  };

  return (
    <AuthContext.Provider
      value={{
        authToken,
        authLogin,
        authLogout,
        username,
        setUsername,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
