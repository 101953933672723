import { Button, Container, Navbar, Nav } from "react-bootstrap";

import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const DashboardHeader = () => {
  const { authLogout, username } = useAuth();

  const navigate = useNavigate();

  const handleLogout = () => {
    authLogout();
    navigate("/login");
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container fluid className="default-container">
        <Navbar.Brand>
          {`Панель специалиста поддержки ${username && `(${username})`}`}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Button variant="danger" onClick={handleLogout}>
              Выход
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default DashboardHeader;
