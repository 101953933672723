import React, { useState } from "react";
import { Col, Form, Modal, Button } from "react-bootstrap";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomDateInputField from "../UI/CustomDateInputField";

const DashboardDropdownFilter = ({
  label,
  placeholder,
  initialOptions,
  value,
  onSelected,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [options, setOptions] = useState(initialOptions);
  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });

  const handleSelect = (e) => {
    const value = e.target.value;

    if (value === "-1") {
      setShowModal(true);
      return;
    }
    onSelected(value);
  };

  const handleModalHide = () => {
    setShowModal(false);
  };

  const handleStartDateChange = (date) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      startDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      endDate: date,
    }));
  };

  const handleDateSubmit = () => {
    setShowModal(false);
    if (!selectedDates.startDate || !selectedDates.endDate) return;

    const formattedStartDate = moment(selectedDates.startDate).format(
      "DD.MM.YYYY"
    );
    const formattedEndDate = moment(selectedDates.endDate).format("DD.MM.YYYY");
    const formattedRequestStartDate = moment(selectedDates.startDate).format(
      "YYYY-MM-DD"
    );
    const formattedRequestEndDate = moment(selectedDates.endDate).format(
      "YYYY-MM-DD"
    );
    const label = `c ${formattedStartDate} по ${formattedEndDate}`;
    const value = JSON.stringify({
      start: formattedRequestStartDate,
      end: formattedRequestEndDate,
    });
    const newOption = {
      label,
      value,
    };

    setOptions((prevOptions) => {
      const filteredOptions = prevOptions.filter((option) => {
        if (typeof option.value == "string" && option.value.startsWith("{")) {
          return false;
        }
        return true;
      });
      return [...filteredOptions, newOption];
    });

    onSelected(value);
  };

  return (
    <>
      <Col md={4} className="mb-4">
        <Form.Group controlId="formPeriod">
          <Form.Label className="label-font">{label}</Form.Label>
          <Form.Select value={value} aria-label={label} onChange={handleSelect}>
            <option value="">{placeholder}</option>
            {options &&
              options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            <option value="-1">Указать период</option>
          </Form.Select>
        </Form.Group>
      </Col>

      <Modal show={showModal} onHide={handleModalHide}>
        <Modal.Header closeButton>
          <Modal.Title>Укажите период</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <CustomDateInputField
              onDateChange={handleStartDateChange}
              date={selectedDates.startDate}
              label="Начало"
              placeholder="дд.мм.гггг"
            />
            <CustomDateInputField
              className="mt-4"
              onDateChange={handleEndDateChange}
              date={selectedDates.endDate}
              label="Конец"
              placeholder="дд.мм.гггг"
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalHide}>
            Отмена
          </Button>
          <Button
            disabled={!(selectedDates.startDate && selectedDates.endDate)}
            variant="primary"
            onClick={handleDateSubmit}
          >
            Принять
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DashboardDropdownFilter;
