import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import NotifierModal from "./NotifierModal";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostRequest } from "../hooks/useApi";
import { useAuth } from "../context/AuthContext";
import HotroomSingleLine from "./UI/icon/HotroomSingleLine";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [notifierDescription, setNotifierDesctiontion] = useState("");
  const [userAuth, setUserAuth] = useState({
    username: "",
    password: "",
  });

  const postRequest = usePostRequest();

  const { authToken, authLogin } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/dashboard";

  const closeNotifier = () => {
    setNotifierDesctiontion("");
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevPasswordVisible) => !prevPasswordVisible);
  };

  const setUsername = (log) => {
    setUserAuth((prevUserAuth) => ({ ...prevUserAuth, username: log }));
  };

  const setPassword = (pass) => {
    setUserAuth((prevUserAuth) => ({ ...prevUserAuth, password: pass }));
  };

  useEffect(() => {
    setButtonDisable(!(userAuth.username && userAuth.password));
  }, [userAuth.username, userAuth.password]);

  useEffect(() => {
    if (authToken) {
      navigate(from, { replace: true });
    }
  }, [authToken, navigate, from]);

  const login = async () => {
    const result = await postRequest("login", userAuth);
    if (result.success) {
      const token = result.data.api_key;
      if (token) authLogin(result.data.api_key, userAuth.username);
      return;
    }
    if (result.errorCode === 401) {
      setNotifierDesctiontion(result.msg);
      return;
    }
    setNotifierDesctiontion("Неизвестная ошибка");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <Row className="w-100">
        <Col md={{ span: 4, offset: 4 }}>
          <div className="p-4 rounded bg-dark text-light">
            <div className="d-flex justify-content-center align-items-center mb-4 mt-4">
              <HotroomSingleLine />
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>
                  <i className="bi bi-person-fill"></i> Логин
                </Form.Label>
                <Form.Control
                  value={userAuth.log}
                  type="text"
                  placeholder="Введите логин"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword" className="mt-3">
                <Form.Label>
                  <i className="bi bi-lock-fill"></i> Пароль
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Введите пароль"
                    value={userAuth.pass}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputGroup.Text
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`bi ${
                        passwordVisible ? "bi-eye-fill" : "bi-eye-slash-fill"
                      }`}
                    ></i>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Button
                type="submit"
                disabled={buttonDisable}
                className="hotroom-yellow-button mt-4 mb-2 w-100 d-flex align-items-center justify-content-center"
              >
                <i
                  className="bi bi-box-arrow-in-right"
                  style={{ fontSize: "1.2rem" }}
                ></i>
                <span className="ms-2">Войти</span>
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
      <NotifierModal
        handleClose={closeNotifier}
        description={notifierDescription}
      />
    </Container>
  );
};

export default Login;
