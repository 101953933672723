import { Button, Row, Col } from "react-bootstrap";
import DashboardDateTimeFilter from "./DashboardDateTimeFilter";
import DashboardTextFilter from "./DashboardTextFilter";
import DashboardDropdownFilter from "./DashboardDropdownFilter";

class FilterTypes {
  static #_TEXT = 0;
  static #_DATE = 1;
  static #_DATETIME = 2;
  static #_DROPDOWN = 3;

  static get TEXT() {
    return this.#_TEXT;
  }
  static get DATE() {
    return this.#_DATE;
  }
  static get DATETIME() {
    return this.#_DATETIME;
  }

  static get DROPDOWN() {
    return this.#_DROPDOWN;
  }
}

const DashboardFilters = ({
  onSearch,
  searchFilters,
  onChangeSearchFilters,
}) => {
  const filters = [
    {
      label: "Период",
      placeholder: "Период не выбран",
      type: FilterTypes.DROPDOWN,
      options: [
        { value: "12", label: "12 часов" },
        { value: "24", label: "24 часа" },
      ],
      value: searchFilters?.period || "",
      handleSelect: (period) => {
        onChangeSearchFilters((prev) => ({ ...(prev || {}), period }));
      },
    },
    {
      label: "Номер заказа TLine",
      placeholder: "Выберите номер заказа",
      type: FilterTypes.TEXT,
      handleInput: (number) => {
        onChangeSearchFilters((prev) => ({ ...(prev || {}), number }));
      },
      value: searchFilters?.number || "",
    },
    {
      label: "Город",
      placeholder: "Введите город",
      type: FilterTypes.TEXT,
      handleInput: (city) => {
        onChangeSearchFilters((prev) => ({ ...(prev || {}), city }));
      },
      value: searchFilters?.city || "",
    },
    {
      label: "Фамилия",
      placeholder: "Выберите фамилию",
      type: FilterTypes.TEXT,
      handleInput: (last_name) => {
        onChangeSearchFilters((prev) => ({ ...(prev || {}), last_name }));
      },
      value: searchFilters?.last_name || "",
    },
    {
      label: "Email",
      placeholder: "Введите email",
      type: FilterTypes.TEXT,
      handleInput: (email) => {
        onChangeSearchFilters((prev) => ({ ...(prev || {}), email }));
      },
      value: searchFilters?.email || "",
    },
    {
      label: "Телефон",
      placeholder: "Введите телефон",
      type: FilterTypes.TEXT,
      handleInput: (phone) => {
        onChangeSearchFilters((prev) => ({ ...(prev || {}), phone }));
      },
      value: searchFilters?.phone || "",
    },
    {
      label: "Дата проживания с",
      placeholder: "дд.мм.гггг",
      type: FilterTypes.DATE,
      date: searchFilters?.date_from,
      handleDateChange: (date_from) => {
        onChangeSearchFilters((prev) => ({
          ...(prev || {}),
          date_from,
        }));
      },
    },
    {
      label: "Дата проживания по",
      placeholder: "дд.мм.гггг",
      type: FilterTypes.DATE,
      date: searchFilters?.date_to,
      handleDateChange: (date_to) => {
        onChangeSearchFilters((prev) => ({
          ...(prev || {}),
          date_to,
        }));
      },
    },
  ];

  return (
    <>
      <Row>
        {filters.map((filter) => {
          switch (filter.type) {
            case FilterTypes.TEXT:
              return (
                <DashboardTextFilter
                  placeholder={filter.placeholder}
                  key={filter.label}
                  label={filter.label}
                  value={filter.value}
                  onInputChange={filter.handleInput}
                />
              );
            case FilterTypes.DATE:
            case FilterTypes.DATETIME:
              return (
                <DashboardDateTimeFilter
                  placeholder={filter.placeholder}
                  key={filter.label}
                  date={filter.date}
                  onDateChange={filter.handleDateChange}
                  label={filter.label}
                />
              );
            case FilterTypes.DROPDOWN:
              return (
                <DashboardDropdownFilter
                  label={filter.label}
                  key={filter.label}
                  placeholder={filter.placeholder}
                  initialOptions={filter.options}
                  value={filter.value}
                  onSelected={filter.handleSelect}
                />
              );
            default:
              return <></>;
          }
        })}
        <Col
          md={4}
          className="mb-4 d-flex align-items-end justify-content-center"
        >
          <Button
            className="w-100 hotroom-yellow-button"
            md={4}
            onClick={onSearch}
            variant="primary"
          >
            Найти
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default DashboardFilters;
