import React, { useEffect, useState } from "react";
import { Container, Accordion, Card } from "react-bootstrap";
import DashboardHeader from "./DashboardHeader";
import DashboardFilters from "./DashboardFilters";
import { usePostRequest } from "../../hooks/useApi";
import DashboardTable from "./DashboardTable";
import moment from "moment";
import "./style/Dashboard.css";

const formatDate = (date, format = "YYYY-MM-DD") => {
  return date?.isValid() ? date.format(format) : null;
};

const preparePeriod = (period) => {
  if (!isNaN(period)) {
    const start = formatDate(moment().subtract(Number(period), "hours"));
    return { start, end: null };
  }
  try {
    const updatedPeriod = JSON.parse(period);
    return updatedPeriod;
  } catch (e) {
    console.log(`Something went wrong: ${e}`);
    return null;
  }
};

const prepareFilters = (filters) => {
  if (!filters) return {};

  const preparedFilters = JSON.parse(JSON.stringify(filters));

  if (preparedFilters?.period) {
    preparedFilters.period = preparePeriod(preparedFilters.period);
  }
  if (filters?.date_from) {
    preparedFilters.date_from = formatDate(filters.date_from);
  }
  if (filters?.date_to) {
    preparedFilters.date_to = formatDate(filters.date_to);
  }
  return preparedFilters;
};

const Dashboard = () => {
  const [reservations, setReservations] = useState([]);
  const [searchFilters, setSearchFilters] = useState(null);
  const getRequest = usePostRequest();

  const fetchReservations = async (filters) => {
    const result = await getRequest("reservations", prepareFilters(filters));
    if (result.success) {
      console.log(result.data);
      setReservations(result.data);
    } else {
      console.log(result.errorCode);
    }
  };

  const handleSearch = () => {
    fetchReservations(searchFilters);
  };

  useEffect(() => {
    fetchReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <DashboardHeader />
      <Container fluid className="default-container mt-4">
        <Accordion className="filters">
          <Card>
            <Accordion.Header className="accordion-header">
              Параметры поиска
            </Accordion.Header>
            <Accordion.Body>
              <DashboardFilters
                onChangeSearchFilters={setSearchFilters}
                searchFilters={searchFilters}
                onSearch={handleSearch}
              />
            </Accordion.Body>
          </Card>
        </Accordion>
        <DashboardTable reservations={reservations} />
      </Container>
    </div>
  );
};

export default Dashboard;
