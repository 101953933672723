import { useState, useRef, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import "moment/locale/ru";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./style/CustomDatePicker.css";

const MIN_DATE = moment().subtract(15, "years");
const MAX_DATE = moment().add(15, "years");

const CustomDateInputField = ({
  label,
  placeholder,
  onDateChange,
  date,
  showTime = false,
  className,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);

  const handleDateChange = (value) => {
    const momentDate = moment(value, "DD.MM.YYYY");

    if (
      !momentDate.isValid() ||
      !(momentDate.isAfter(MIN_DATE) && momentDate.isBefore(MAX_DATE))
    ) {
      return;
    }

    onDateChange(momentDate);
    setShowPicker(false);
  };

  const clearDate = () => {
    onDateChange(null);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  return (
    <Form.Group className={className} controlId={`formPeriod`}>
      <Form.Label className="label-font">{label}</Form.Label>
      <InputGroup>
        <Form.Control
          value={date ? date.format("DD.MM.YYYY") : ""}
          readOnly
          placeholder={placeholder}
        />
        <InputGroup.Text
          onClick={() => setShowPicker(true)}
          style={{ cursor: "pointer" }}
        >
          <i className="bi bi-calendar"></i>
        </InputGroup.Text>
        <InputGroup.Text
          onClick={clearDate}
          style={{
            cursor: date ? "pointer" : "not-allowed",
          }}
        >
          <i
            style={{ opacity: date ? "1.0" : "0.5" }}
            className="bi bi-x-lg"
          ></i>
        </InputGroup.Text>
      </InputGroup>
      {showPicker && (
        <div ref={pickerRef}>
          <Datetime
            dateFormat="DD.MM.YYYY"
            timeFormat={showTime}
            locale="ru"
            value={date ? moment(date, "DD.MM.YYYY") : null}
            onChange={(date) => handleDateChange(date.format("DD.MM.YYYY"))}
            isValidDate={(currentDate) =>
              currentDate.isAfter(MIN_DATE) && currentDate.isBefore(MAX_DATE)
            }
            inputProps={{ className: "form-control d-none" }}
            open={showPicker}
            onBlur={() => setShowPicker(false)}
          />
        </div>
      )}
    </Form.Group>
  );
};

export default CustomDateInputField;
