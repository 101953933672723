import React from "react";
import { Table, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatCurrency, formatToMoney } from "../../utils/formatters";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/DashboardTable.css";
import "../../style/globals.css";

const DashboardTable = ({ reservations }) => {
  const navigate = useNavigate();

  const handleViewReservation = (id) => {
    navigate(`/reservation/${id}`);
  };

  if (!reservations.length) {
    return (
      <div className="mt-5" style={{ display: "block" }}>
        <h1 className="text-muted" style={{ textAlign: "center" }}>
          Не найдено
        </h1>
      </div>
    );
  }

  return (
    <>
      <h1>Бронирования</h1>
      <Table striped bordered hover className="table-sm rounded-table">
        <thead className="thead-dark">
          <tr>
            <th>Дата заказа</th>
            <th>Сумма заказа</th>
            <th>Статус заказа</th>
            <th>Операция</th>
            <th>Город</th>
            <th>Отель</th>
            <th>Дата прибытия</th>
            <th>Дата отбытия</th>
            <th>Номер бронирования</th>
            <th>Статус бронирования</th>
            <th>Гость</th>
            <th>Телефон</th>
            <th>Email</th>
            <th>Статус возврата</th>
            <th>Сумма возврата</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {reservations &&
            reservations.map((reservation) => (
              <tr key={reservation.order_id}>
                <td>{reservation.order_date /* Дата заказа */}</td>
                <td className="money">
                  {
                    `${formatToMoney(reservation.order_sum)} ${
                      formatCurrency(reservation.order_currency) || ""
                    }` /* Сумма заказа */
                  }
                </td>
                <td>{reservation.order_status /* Статус заказа */}</td>
                <td>{reservation.operation_code /* Операция */}</td>
                <td>{reservation.city_name /* Город */}</td>
                <td>{reservation.hotel_name /* Отель */}</td>
                <td>{reservation.booking_date_from /* Дата прибытия */}</td>
                <td>{reservation.booking_date_to /* Дата отбытия */}</td>
                <td>{reservation.booking_no /* Номер бронирования */}</td>
                <td>{reservation.booking_status /* Статус бронирования */}</td>
                <td>{reservation.customer_name.trim() /* Гость */}</td>
                <td>{reservation.customer_phone /* Телефон */}</td>
                <td>{reservation.customer_email /* Email */}</td>
                <td>{reservation.refund_status /* Статус возврата */}</td>
                <td className="money">
                  {
                    `${formatToMoney(reservation.refund_sum)} ${
                      formatCurrency(reservation.order_currency) || ""
                    }` /* Сумма возврата */
                  }
                </td>
                <td>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => {
                      handleViewReservation(reservation.order_id);
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default DashboardTable;
