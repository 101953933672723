import { Col } from "react-bootstrap";
import CustomDateInputField from "../UI/CustomDateInputField";

const DashboardDateTimeFilter = ({
  label,
  placeholder,
  onDateChange,
  date,
  showTime = false,
}) => {
  return (
    <Col md={4} className="mb-4">
      <CustomDateInputField
        onDateChange={onDateChange}
        label={label}
        date={date}
        placeholder={placeholder}
        showTime={showTime}
      />
    </Col>
  );
};

export default DashboardDateTimeFilter;
