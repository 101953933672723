import "./style/globals.css";
import "./style/variables.css";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ReservationDetails from "./components/reservation/ReservationDetails";
function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/reservation/:id"
            element={
              <PrivateRoute>
                <ReservationDetails />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="dashboard" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
